import { useEffect, useState } from "react";

export const useDateTimeFormat = (options?: Intl.DateTimeFormatOptions) => {
  const [dateTimeFormat, setDateTimeFormat] = useState(
    () => new Intl.DateTimeFormat("en-us", options)
  );

  useEffect(
    () => setDateTimeFormat(new Intl.DateTimeFormat(undefined, options)),
    [options]
  );

  return dateTimeFormat;
};

export const longDateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
  timeZone: "UTC",
} satisfies Intl.DateTimeFormatOptions;

export const shortDateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "numeric",
  timeZone: "UTC",
} satisfies Intl.DateTimeFormatOptions;
