import { useQueryClient } from "@tanstack/react-query";
import type { Mutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";

import type { RouterInputs } from "@sunblocks/trpc";

import { trpc } from "src/trpc/client";

import { useAuth } from "./Auth";
import { useScopedLocalStorage } from "./use-local-storage";

export const useScores = () => {
  const { session } = useAuth();

  const { accessToken } = session ?? {};

  const { data: rawScores = {} } = trpc.scores.getAll.useQuery(undefined, {
    enabled: Boolean(accessToken),
    refetchOnWindowFocus: false,
    staleTime: 0,
    trpc: {
      context: { headers: { authorization: `Bearer ${accessToken}` } },
    },
  });

  const [submitScoreQueue = [], setSubmitScoreQueue] = useScopedLocalStorage(
    (stored) => stored?.submitScoreQueue,
    (stored, submitScoreQueue) => ({
      ...stored,
      submitScoreQueue,
    })
  );

  const utils = trpc.useUtils();

  const { mutate: submitScoreRaw } = trpc.scores.submit.useMutation({
    trpc: {
      context: { headers: { authorization: `Bearer ${accessToken}` } },
    },
    onSuccess: (data, { levelId, moves }) => {
      utils.scores.getAll.setData(undefined, (previousScores) => ({
        ...previousScores,
        [levelId]: Math.min(
          previousScores?.[levelId] ?? Number.MAX_SAFE_INTEGER,
          moves.length
        ),
      }));

      setSubmitScoreQueue((queue) =>
        queue?.filter(({ levelId: queueLevelId }) => queueLevelId !== levelId)
      );
    },
  });

  const queryClient = useQueryClient();

  const [allMutations, setAllMutations] = useState(
    () =>
      [...queryClient.getMutationCache().getAll()] as Mutation<
        void,
        unknown,
        any
      >[]
  );

  useEffect(
    () =>
      queryClient.getMutationCache().subscribe((event) => {
        if (event.type === "observerOptionsUpdated") {
          return;
        }

        setAllMutations([
          ...queryClient.getMutationCache().getAll(),
        ] as Mutation<void, unknown, any>[]);
      }),
    [queryClient]
  );

  const submitScoreMutations = useMemo(
    () =>
      allMutations
        .filter(
          (
            mutation
          ): mutation is Mutation<
            void,
            unknown,
            RouterInputs["scores"]["submit"]
          > =>
            Array.isArray(mutation.options.mutationKey?.[0]) &&
            mutation.options.mutationKey?.[0]?.[0] === "scores" &&
            mutation.options.mutationKey?.[0]?.[1] === "submit"
        )
        .map(({ state: { variables } }) => variables!),
    [allMutations]
  );

  useEffect(() => {
    if (!submitScoreQueue.length || !accessToken) {
      return;
    }

    const submitScoreLevelIds = new Set(
      submitScoreMutations.map(({ levelId }) => levelId)
    );

    submitScoreQueue
      .filter(({ levelId }) => !submitScoreLevelIds.has(levelId))
      .map((data) =>
        // HACK This will happen as may times as useScores() is mounted, which means that a page might do this multiple times.
        // We're returning scores from useLevelState to avoid this, which is bad.
        // TODO We need to make sure that this only happens once.
        submitScoreRaw(data)
      );
  }, [accessToken, submitScoreMutations, submitScoreQueue, submitScoreRaw]);

  const scores = useMemo(
    () =>
      [...submitScoreQueue, ...submitScoreMutations].reduce(
        (scores, { levelId, moves }) => ({
          ...scores,
          [levelId]: Math.min(
            scores?.[levelId] ?? Number.MAX_SAFE_INTEGER,
            moves.length
          ),
        }),
        rawScores
      ),
    [rawScores, submitScoreMutations, submitScoreQueue]
  );

  return {
    scores,
    submitScore: useCallback(
      (data: Parameters<typeof submitScoreRaw>[0]) =>
        setSubmitScoreQueue((queue) => [...(queue ?? []), data]),
      [setSubmitScoreQueue]
    ),
  };
};
