import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import SentimentDissatisfiedRoundedIcon from "@mui/icons-material/SentimentDissatisfiedRounded";
import { mapValues, omit } from "lodash/fp";
import type { GetStaticProps, InferGetStaticPropsType } from "next";
import Link from "next/link";
import { usePostHog } from "posthog-js/react";
import { Fragment, useMemo } from "react";

import {
  Card,
  CardGrid,
  CardText,
  H1,
  LevelCard,
  PageHeader,
} from "@sunblocks/components";
import { dateStringToDate, dateToDateString, minDate } from "@sunblocks/utils";

import { useAuth } from "src/components/Auth";
import { useDateStringToday } from "src/components/use-date-string-today";
import {
  longDateTimeFormatOptions,
  shortDateTimeFormatOptions,
  useDateTimeFormat,
} from "src/components/use-date-time-format";
import { useMuted } from "src/components/use-muted";
import { useScores } from "src/components/use-scores";
import { useScopedSessionStorage } from "src/components/use-session-storage";
import type { PageProps } from "src/pages/_app.page";
import { tailwindConfig } from "src/tailwind-config";
import { trpc } from "src/trpc/client";

const {
  theme: { spacing },
} = tailwindConfig;

export const getStaticProps = (async () => ({
  props: {
    minDateString: dateToDateString(minDate()),
  },
})) satisfies GetStaticProps<PageProps>;

const cardClassName =
  "max-h-[calc(33.333333333333336vw-0.6666666666666666rem-1.3333333333333333rem)]";

const cardWidthClassName =
  "aspect-square w-40 max-w-[calc(33.333333333333336vw-0.6666666666666666rem-1.3333333333333333rem)]";

const cardDoubleWidthClassName =
  "aspect-[21/10] w-[21rem] max-w-[calc(66.6667vw-3rem)]";

const extraShortDateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  timeZone: "UTC",
} satisfies Intl.DateTimeFormatOptions;

const Page = ({
  minDateString,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const {
    isFetched: isFetchedDailies,
    data: { pages: dailiesPages = [] } = {},
  } = trpc.dailies.getMany.useInfiniteQuery({});

  const dateStringToday = useDateStringToday() ?? minDateString;

  const dailies = useMemo(
    () =>
      dailiesPages
        .flatMap((daily) => daily)
        .filter(
          ({ date: { current: dailyDateString } }) =>
            dailyDateString <= dateStringToday
        )
        .slice(0, 7),
    [dailiesPages, dateStringToday]
  );

  const { isFetched: isFetchedPacks, data: packs = [] } =
    trpc.packs.getAll.useQuery();

  const { scores } = useScores();

  const [firstTimeWin = {}, setFirstTimeWin] = useScopedSessionStorage(
    ({ firstTimeWin = {} } = {}) => firstTimeWin,
    (
      { firstTimeWin: prevFirstTimeWin, ...stored } = {},
      firstTimeWin = {}
    ) => ({
      ...stored,
      firstTimeWin,
    })
  );

  const finished = useMemo(
    () => ({
      ...mapValues(() => true, scores),
      ...mapValues(() => false, firstTimeWin),
      [packs
        .flatMap(({ levels }) => levels)
        .find(({ _id }) => _id in firstTimeWin)?._id ??
      dailies.find(({ level: { _id } }) => _id in firstTimeWin)?.level._id ??
      ""]: true,
    }),
    [dailies, firstTimeWin, packs, scores]
  );

  const longDateTimeFormat = useDateTimeFormat(longDateTimeFormatOptions);
  const shortDateTimeFormat = useDateTimeFormat(shortDateTimeFormatOptions);
  const extraShortDateTimeFormat = useDateTimeFormat(
    extraShortDateTimeFormatOptions
  );

  const posthog = usePostHog();

  const { login, logout, session } = useAuth();

  const { accessToken } = session ?? {};

  const { data: user } = trpc.users.me.useQuery(undefined, {
    enabled: Boolean(accessToken),
    trpc: { context: { headers: { authorization: `Bearer ${accessToken}` } } },
    onSuccess: ({ email, name, picture }) =>
      posthog.setPersonProperties({
        email,
        name,
        picture,
      }),
  });

  const [muted, setMuted] = useMuted();

  return (
    <>
      <PageHeader
        login={login}
        logout={logout}
        muted={muted}
        session={session}
        setMuted={setMuted}
        user={user}
      />
      <div className="pb-8">
        {isFetchedDailies && !dailies.length ? null : (
          <>
            <H1 className="mx-8" skeleton={!isFetchedDailies}>
              Level of the Day
            </H1>
            <div className="relative my-2 before:pointer-events-none before:absolute before:bottom-4 before:left-0 before:top-0 before:z-10 before:w-8 before:bg-gradient-to-r before:from-white after:pointer-events-none after:absolute after:bottom-4 after:right-0 after:top-0 after:z-10 after:w-8 after:bg-gradient-to-l after:from-white before:dark:from-zinc-950 after:dark:from-zinc-950">
              <div
                className={`flex flex-row gap-4 px-8 pb-4 ${
                  !isFetchedDailies ? "overflow-x-hidden" : "overflow-x-scroll"
                }`}
              >
                {!isFetchedDailies ? (
                  <>
                    <Card
                      skeleton
                      className={`${cardDoubleWidthClassName} ${cardClassName}`}
                    />
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Card
                        skeleton
                        key={index}
                        className={`${cardWidthClassName} ${cardClassName}`}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {dailies[0]?.date.current !== dateStringToday && (
                      <Card
                        className={`justify-end bg-stone-200 text-slate-950 ${cardDoubleWidthClassName} ${cardClassName}`}
                        title={longDateTimeFormat.format(
                          dateStringToDate(dailies[0]!.date.current)
                        )}
                      >
                        <SentimentDissatisfiedRoundedIcon
                          className="aspect-square text-red-500"
                          style={{
                            flexShrink: 1,
                            height: "100%",
                            maxHeight: spacing.blockContent,
                            maxWidth: spacing.blockContent,
                            width: "100%",
                          }}
                        />
                        <CardText>No Level Today</CardText>
                      </Card>
                    )}
                    {dailies.map(
                      ({
                        date: { current: dailyDateString },
                        level: { elements, _id: levelId },
                      }) => {
                        const isToday = dailyDateString === dateStringToday;
                        const date = dateStringToDate(dailyDateString);

                        return (
                          <Link
                            key={dailyDateString}
                            href={`/daily${
                              isToday ? "" : `/${dailyDateString}`
                            }`}
                            title={longDateTimeFormat.format(date)}
                          >
                            <LevelCard
                              className={`justify-end ${cardClassName} ${
                                isToday
                                  ? cardDoubleWidthClassName
                                  : cardWidthClassName
                              }`}
                              animating={firstTimeWin[levelId]}
                              elements={elements ?? undefined}
                              finished={finished[levelId]}
                              onFinishedAnimationComplete={() =>
                                setFirstTimeWin(omit([levelId]))
                              }
                            >
                              <CardText>
                                {(isToday
                                  ? shortDateTimeFormat
                                  : extraShortDateTimeFormat
                                ).format(date)}
                              </CardText>
                            </LevelCard>
                          </Link>
                        );
                      }
                    )}
                    <Link href="/daily/archive" title="Archive">
                      <Card
                        className={`justify-end bg-block-inner-inert text-slate-950 ${cardWidthClassName} ${cardClassName}`}
                      >
                        <CalendarMonthRoundedIcon
                          className="aspect-square text-block-light-normal-inactive"
                          style={{
                            flexShrink: 1,
                            height: "100%",
                            maxHeight: spacing.blockContent,
                            maxWidth: spacing.blockContent,
                            width: "100%",
                          }}
                        />
                        <CardText>Archive</CardText>
                      </Card>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {isFetchedPacks && !packs.length ? null : (
          <div className="px-8">
            {!isFetchedPacks
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Fragment key={index}>
                    <H1 skeleton />
                    <CardGrid>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Card
                          skeleton
                          key={index}
                          className={`${cardWidthClassName} ${cardClassName}`}
                        />
                      ))}
                    </CardGrid>
                  </Fragment>
                ))
              : packs.map(({ levels, title, slug: { current: packSlug } }) => (
                  <Fragment key={packSlug}>
                    <H1>{title}</H1>
                    <CardGrid>
                      {levels?.map(({ elements, _id: levelId }, levelIndex) => (
                        <Link
                          key={levelId}
                          href={`/pack/${packSlug}/level/${levelId}`}
                          title={`${title} - Level ${levelIndex + 1}`}
                        >
                          <LevelCard
                            className={`justify-center ${cardWidthClassName} ${cardClassName}`}
                            animating={firstTimeWin[levelId]}
                            elements={elements ?? undefined}
                            finished={finished[levelId]}
                            onFinishedAnimationComplete={() =>
                              setFirstTimeWin(omit([levelId]))
                            }
                          />
                        </Link>
                      ))}
                    </CardGrid>
                  </Fragment>
                ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Page;
