import { subMinutes } from "date-fns/fp";
import { useEffect, useState } from "react";

import { dateToDateString, fp } from "@sunblocks/utils";

export const useDateStringToday = () => {
  const [value, setValue] = useState<string>();

  useEffect(
    () =>
      setValue(
        fp(
          new Date(),
          subMinutes(new Date().getTimezoneOffset()),
          dateToDateString
        )
      ),
    []
  );

  return value;
};
